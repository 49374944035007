import React, { useContext, useEffect, useState, useRef, useLayoutEffect } from 'react'
import ValuebetContext from '../../../../../context/Valuebet/ValuebetContext';
import { errorNotification } from '../../../../../Components/CustComp/PPHAlert';
import { OFFLINE_ODD } from '../../../../../constant'
import { getReadablePeriod } from '../../../../../Utils/sportsbook';


export default function BetOption({ value, market_type, team_type, league_id, match_id, team_code, team_name, line, period }) {
    const [isSelected, setIsSelected] = useState(false);
    const timer = useRef(null);
    const preVal = useRef(null);
    const { leagueEvents, betDetails, setBetDetails, removeSelectedBet } = useContext(ValuebetContext);

    // Creating Bets
    const createBetData = async () => {
        if (betDetails?.bets?.length >= 6) {
            errorNotification("Bets must be less-than or equal to 6.")
            return
        }
        let id = value.id
        let odd_money = value.odd_money > 0 ? `+${value.odd_money}` : value.odd_money

        let sport_name = '';
        let league_name = '';
        let market_name = '';
        let odd_points = '';
        let sub_title = '';
        let odd_name = '';
        let period_name = getReadablePeriod(period)

        if (market_type === 'moneyline') {
            market_name = "Moneyline";
            odd_points = "0"
            odd_name = team_type;
            sub_title = `${market_name} ${odd_money} for ${period_name}`
        } else if (market_type === 'spread') {
            market_name = "Spread";
            odd_points = value.odd_points;
            odd_name = team_type;
            sub_title = `${market_name} ${odd_points > 0 ? `+${odd_points}` : odd_points === "0" ? `pk` : odd_points} ${odd_money} for ${period_name}`
        } else if (market_type === 'total') {
            market_name = "Total";
            odd_points = value.odd_points;
            if (team_type === "HOME") {
                odd_name = "UNDER";
            } else if (team_type === "AWAY") {
                odd_name = "OVER";
            }
            sub_title = `${market_name}(${odd_name}) ${odd_points} ${odd_money} for ${period_name}`
        }

        let title = '';
        for (let league of leagueEvents) {
            if (league.league_id === league_id) {
                league_name = league.league_name;
                sport_name = league.sport_name;
                title = league.title;
                if (market_type === 'moneyline' || market_type === 'spread') {
                    title += ` - ${team_name}`
                } else if (market_type === 'total') {
                    for (let match of league.events) {
                        if (match.id === match_id) {
                            title += ` - ${match.home_team_name} / ${match.away_team_name}`
                            break;
                        }
                    }
                }
                break;
            }
        }

        let tmp_bet = JSON.parse(JSON.stringify(betDetails))
        tmp_bet.bets.push({
            id: id,
            odd_points: odd_points,
            odd_money: odd_money,
            market_name: market_name,
            odd_name: odd_name,
            sport_name: sport_name,
            league_name: league_name,
            league_id: league_id,
            match_id: match_id,
            team_code: team_code,
            title: title,
            sub_title: sub_title,
            init_sub_title: sub_title,
            line: line,
            period_name: period_name,
            period: period
        })
        setBetDetails(tmp_bet);
    }

    const betAction = () => {
        if (!isSelected) {
            createBetData();
        } else {
            console.log("value",value)
            removeSelectedBet(value);
        }
    }

    useLayoutEffect(() => {
        let flag = false;
        for (let bet of betDetails.bets) {
            if (bet?.id === value?.id) {
                flag = true;
                break;
            }
        }
        setIsSelected(flag);
        // eslint-disable-next-line
    }, [betDetails, value])

    const highlighterTool = () => {
        if (value?.is_updated) {
            value.is_updated = false;
            try {
                if (timer.current) {
                    clearTimeout(timer.current)
                }
                let ele = document.getElementById(value.id);
                ele.classList.add(isSelected ? 'orange-highlight' : 'red-highlight');
                timer.current = setTimeout(() => {
                    if (ele) {
                        ele.classList.remove('orange-highlight', 'red-highlight');
                    }
                }, 10000)
            } catch (err) {
                console.log(err)
            }
        } else if (preVal?.current?.id !== value?.id) {
            if (timer.current) {
                let ele = document.getElementById(value.id);
                if (ele) {
                    ele.classList.remove('orange-highlight', 'red-highlight');
                    clearTimeout(timer.current)
                }
            }
        }
    }

    useEffect(() => {
        highlighterTool();
        return (() => {
            preVal.current = value;
        })
        // eslint-disable-next-line
    }, [value])

    return (
        (!value) ?
            <button className="odd-disable-button" disabled> N/A </button> :
            (value?.odd_points === OFFLINE_ODD || value?.odd_money === OFFLINE_ODD) ?
                <div id={value.id} className="odd-disable-button"> - </div> :
                <div id={value.id} role="button" className={`odd-button text-nowrap ${isSelected && 'odd-button-active'}`} onClick={betAction}>
                    {market_type === 'moneyline' && <span>{(value?.odd_money > 0) ? '+' + value?.odd_money : value?.odd_money}</span>}
                    {market_type === 'spread' && <div ><span className='pe-1'>{(value?.odd_points > 0) ? '+' + value?.odd_points : (value?.odd_points === '0') ? 'pk' : value?.odd_points}</span><span className='ps-2'>{(value?.odd_money > 0) ? '+' + value?.odd_money : value?.odd_money}</span></div>}
                    {market_type === 'total' && <div><span className='pe-1'>{team_type === 'AWAY' ? "O" : "U"} {value?.odd_points}</span><span className='ps-2'>{(value?.odd_money > 0) ? '+' + value?.odd_money : value?.odd_money}</span></div>}
                </div>
    )
}